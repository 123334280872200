import React, { useState, useEffect, useRef } from 'react'
import { Button, Dropdown, Modal } from 'semantic-ui-react'
import CommonButtons from './CommonButtons'
import close from '../assets/images/close.png'

const AudioInputOutput = ({ isModalClose, open, startCall }) => {
  const [devices, setDevices] = useState({
    inputDevices: [],
    outputDevices: [],
    ringtoneDevices: []
  })
  const [selectedInput, setSelectedInput] = useState(null)
  const [selectedOutput, setSelectedOutput] = useState(null)
  const [selectedRingtone, setSelectedRingtone] = useState(null)
  const [isTesting, setIsTesting] = useState(false)

  const [stream, setStream] = useState(null) // Store the media stream
  const audioElementRef = useRef(null) // Ref for the audio element for output

  // Fetch devices on modal open
  const fetchDevices = async () => {
    try {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      const inputDevices = mediaDevices.filter(
        device => device.kind === 'audioinput'
      )
      const outputDevices = mediaDevices.filter(
        device => device.kind === 'audiooutput'
      )
      const ringtoneDevices = mediaDevices.filter(
        device => device.kind === 'audiooutput'
      )
      setDevices({
        inputDevices,
        outputDevices,
        ringtoneDevices
      })
    } catch (err) {
      console.error('Error fetching media devices:', err)
    }
  }

  useEffect(() => {
    if (open) {
      fetchDevices()
    }
  }, [open])

  // Function to handle testing devices
  const handleTestDevice = deviceType => {
    setIsTesting(true)
    setTimeout(() => setIsTesting(false), 2000) // Simulating a test
  }

  // Refresh devices
  const handleRefreshDevices = () => {
    fetchDevices()
  }

  // Function to start the call with selected devices
  const handleStartCall = async () => {
    if (!selectedInput || !selectedOutput) {
      alert(
        'Please select both input (microphone) and output (speakers) devices.'
      )
      return
    }

    try {
      // Get the selected input device stream
      const inputStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedInput ? { exact: selectedInput } : undefined
        }
      })

      // Use the selected output device for audio playback
      if (audioElementRef.current && selectedOutput) {
        audioElementRef.current.setSinkId(selectedOutput)
      }

      // Create the call stream with the selected input
      startCall(inputStream) // Assuming startCall is passed as a prop to initialize the call

      setStream(inputStream)
    } catch (err) {
      console.error('Error starting call with selected devices:', err)
    }
  }

  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"
    >
      <div className="modal-header align-items-center">
        <h5 className="mb-0">Audio Input Output Setting</h5>
        <div className="close-icon" onClick={isModalClose}>
          <img src={close} alt="close" />
        </div>
      </div>

      <Modal.Content style={{ padding: '30px' }}>
        <div className="mb-3">
          <p className="mb-1">Select Output Device</p>
          <div className="row">
            <div className="col-md-9">
              <Dropdown
                placeholder="Select Output Device"
                fluid
                selection
                value={selectedOutput}
                options={devices.outputDevices.map(device => ({
                  key: device.deviceId,
                  text: device.label,
                  value: device.deviceId
                }))}
                onChange={(e, { value }) => setSelectedOutput(value)}
              />
            </div>
            <Button className="ml-3" onClick={() => handleTestDevice('output')}>
              {isTesting ? 'Testing...' : 'Test'}
            </Button>
          </div>
        </div>

        <div className="mb-3">
          <p className="mb-1">Select Input Device</p>
          <div className="row">
            <div className="col-md-9">
              <Dropdown
                placeholder="Select Input Device"
                fluid
                selection
                value={selectedInput}
                options={devices.inputDevices.map(device => ({
                  key: device.deviceId,
                  text: device.label,
                  value: device.deviceId
                }))}
                onChange={(e, { value }) => setSelectedInput(value)}
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <p className="mb-1">Select Ringtone Device</p>
          <div className="row">
            <div className="col-md-9">
              <Dropdown
                placeholder="Select Ringtone Device"
                fluid
                selection
                value={selectedRingtone}
                options={devices.ringtoneDevices.map(device => ({
                  key: device.deviceId,
                  text: device.label,
                  value: device.deviceId
                }))}
                onChange={(e, { value }) => setSelectedRingtone(value)}
              />
            </div>
            <Button
              className="ml-3"
              onClick={() => handleTestDevice('ringtone')}
            >
              {isTesting ? 'Testing...' : 'Test'}
            </Button>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions className="send-message__btn">
        <CommonButtons
          content="Refresh Devices"
          style={{ background: '#9da3b1', color: 'white' }}
          onClick={handleRefreshDevices}
        />
        <Button color="blue" onClick={handleStartCall}>
          Start Call
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AudioInputOutput
